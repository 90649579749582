import React from "react";
import { navigate } from "gatsby";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";

import { Maybe, SanityBlogsConnection } from "@graphql-types";
import { Container, H2, GridContainer, TextButton } from "@util/standard";
import Preview from "@components/shared/preview";
import { MOBILE_BREAKPOINT, pages } from "@util/constants";

const Wrapper = styled(Container)`
  flex-direction: column;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

interface Props {
  data: SanityBlogsConnection;
  currentBlogTitle: Maybe<string> | undefined;
}

const RelatedPosts = ({ data, currentBlogTitle }: Props) => {
  if (data == null) {
    return null;
  }

  const { nodes } = data;
  const posts = nodes
    .filter((node) => node.title !== currentBlogTitle)
    .slice(0, 3);

  if (posts.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Container
        autoWidthNoMargin
        alignItems="center"
        justifyContent="space-between"
      >
        <H2>Related Posts</H2>
        <TextButton
          onClick={() => navigate(`${pages.updates}/`)}
        >{`< Back`}</TextButton>
      </Container>
      <GridContainer repeat={3} tabletRepeat={1} columnGap="20px" rowGap="20px">
        {posts.map((blog) => {
          if (blog == null) {
            return null;
          }

          return (
            <Preview
              key={blog._id}
              title={blog.title}
              category={blog.category?.title}
              image={blog.image?.asset?.fluid as FluidObject}
              mobileImage={blog.mobileImage}
              slug={blog.slug?.current}
            />
          );
        })}
      </GridContainer>
    </Wrapper>
  );
};
export default RelatedPosts;
