import React from "react";
import { graphql, navigate, PageProps } from "gatsby";
import { FluidObject } from "gatsby-image";

import { Query, SanityBlogsConnection } from "@graphql-types";
import { BlocksContent, Image, Video } from "@sub";
import { Container, P, TextButton } from "@util/standard";
import {
  fontSizes,
  MOBILE_BREAKPOINT,
  pages,
  TABLET_BREAKPOINT,
} from "@util/constants";
import RelatedPosts from "@components/updates/relatedPost";
import { BlogTitle, CenteredBlockContainer, Wrapper } from "./template.styles";
import SEO from "@components/shared/seo";
import styled from "styled-components";
import { Blocks } from "@components/shared/blocks";

interface Data extends Query {
  relatedPosts: SanityBlogsConnection;
}
interface Props extends PageProps {
  data: Data;
}

export default function BlogTemplate({ data }: Props) {
  const {
    allSanityBlogs: {
      edges: [{ node: blog }],
    },
    relatedPosts,
  } = data;

  const slug = blog.slug?.current;

  return (
    <PageWrapper>
      <SEO seoData={blog.seo} slug={`updates/${slug}`} />
      <article itemScope itemType="http://schema.org/BlogPosting">
        <StyledBlogTemplate>
          <CenteredBlockContainer>
            <Container margin="75px 0 0 0" className="date-stamp">
              <TextButton
                onClick={() => navigate(`${pages.updates}/`)}
              >{`< Back`}</TextButton>
              <P>|</P>
              <P>{blog.publishDate}</P>
            </Container>
            <BlogTitle>{blog.title}</BlogTitle>
          </CenteredBlockContainer>
          <Container
            margin="0 auto 60px auto"
            maxHeight="756px"
            maxWidth="1000px"
          >
            <Image fluid={blog.image.asset.fluid} objectFit="cover" />
          </Container>
          <Container maxWidth="1200px" width="80%" margin="auto">
            <Blocks data={blog.blogContent} />
          </Container>
        </StyledBlogTemplate>
      </article>
      <Container
        width="90%"
        margin="200px auto 0 auto"
        tabletMargin="100px auto"
      >
        <RelatedPosts data={relatedPosts} currentBlogTitle={blog.title} />
      </Container>
    </PageWrapper>
  );
}

const StyledBlogTemplate = styled(Wrapper)`
  li {
    color: blue;
  }
`;

const PageWrapper = styled.div`
  h1,
  h2,
  h3,
  p,
  span {
    margin: 60px auto;
    text-align: left;
  }
  strong {
    font-size: 15px;
    line-height: 18px;
  }

  h1 {
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 100px; /* 100% */
    width: fit-content;
    margin-left: 0;
    margin-top: 0px;
  }
  h2 {
    margin-top: 30px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 23px;
      line-height: 25px;
      margin-bottom: 0;
    }
  }
  h3 {
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      // font-size: 23px;
      // line-height: 25px;
      margin-bottom: 0;
    }
  }
  p,
  strong,
  li {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
    font-weight: 400;
  }

  strong {
    font-family: "NeueMontreal-Bold";
  }

  .date-stamp {
    margin-bottom: 30px;
    p {
      font-size: 14px;
      margin: 0 10px;
    }
  }

  .block-content-wrapper {
    width: 100%;
  }

  .blocks-wrapper {
    row-gap: 10px;
    margin-top: 0px;
  }

  .blocks-content {
    p {
      margin: 20px 0;
      min-height: 20px;
    }

    a {
      color: #0000ff;
      font-size: inherit;
    }

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      max-width: 100%;
    }
  }
`;

export const query = graphql`
  query blogQuery($slug: String, $category: String) {
    allSanityBlogs(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          slug {
            current
          }
          seo {
            pageDescription
            pageKeyWords
            pageTitle
            slug {
              current
            }
            ogImage {
              asset {
                url
              }
            }
          }
          title
          image {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                ...GatsbySanityImageFluid
              }
            }
          }
          publishDate(formatString: "DD MMM, YYYY")
          blogContent {
            ...sanityPageBlocks
            ... on SanityColumn {
              _key
              _rawColumnContent
              _type
            }
            ... on SanityImageTextColumn {
              _key
              firstColumn {
                asset {
                  fluid {
                    srcWebp
                    srcSetWebp
                    ...GatsbySanityImageFluid
                  }
                }
              }
              reverse
              secondColumn {
                _key
                _rawColumnContent
              }
              _type
            }
            ... on SanityVideoTextColumn {
              _key
              _type
              firstColumn {
                videoFile {
                  asset {
                    url
                  }
                }
              }
              secondColumn {
                _rawColumnContent
              }
              reverse
            }
          }
        }
      }
    }
    relatedPosts: allSanityBlogs(
      limit: 4
      filter: { category: { title: { eq: $category } } }
    ) {
      nodes {
        _id
        title
        image {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              ...GatsbySanityImageFluid
            }
          }
        }
        mobileImage {
          ...sanityImageWithMeta
        }
        slug {
          current
        }
        category {
          title
        }
      }
    }
  }
`;
